<template>
    <div>
        <Table
            :isLoading="isLoading"
            :columnConfig="commissionTableColumns"
            :excelConfig="excelConfig"
            :toolbox="
                $isAdmin()
                    ? ['columnSetting', 'zooming']
                    : ['columnSetting', 'excel', 'zooming']
            "
            :isFocusRow="true"
            :rows="tableShowData"
            :isPagination="true"
            :sortDefault="{ sort: 'desc', sortBy: 'newPeriod' }"
            :isFixedHeader="true"
        >
            <template v-slot:downloadZip="{ isHeader, row }">
                <div
                    v-if="
                        !isHeader && row.newPeriod !== '合計' && canDownloadZip
                    "
                    class="to-right"
                    @click="downloadSalesZip(row)"
                >
                    <div
                        :class="
                            this.downloadingKeys.includes(row?.key)
                                ? 'process-loading-icon'
                                : 'download-link-icon'
                        "
                    />
                </div>
            </template>
        </Table>
    </div>
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import { commissionColumns } from '@/assets/javascripts/commission/setting'
import { downloadSalesZipAPI } from '@/assets/javascripts/api'

export default {
    name: 'CommissionTable',
    components: {
        Table
    },
    props: {
        mTypeList: {
            type: Array,
            default: function () {
                return []
            }
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        canDownloadZip: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        transformData: function (rows) {
            return rows.map((item) => {
                const period = item.period.substring(0, 4)
                const month = item.period.substring(4, 6)
                item.key = `${item.manCode}-${item.period}-${item.type}`
                item.newPeriod = `${period}/${month}`
                item.mTypeName =
                    this.mTypeList.find(
                        (mTypeObj) =>
                            mTypeObj.mType.toString() === item.mType.toString()
                    )?.name || '-'
                return item
            })
        },
        downloadSalesZip: async function (row) {
            if (!this.$hasPermission('downloadSales')) {
                this.$showErrorMessage(
                    '此帳號尚無下載佣金明細表的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }
            const item = row.key
            if (this.downloadingKeys.includes(item)) return
            this.$setGaEvent('downloadSalesZip', 'click-Button')

            const payload = {
                receiveInfos: [
                    {
                        manCode: item.split('-')[0],
                        period: item.split('-')[1]
                    }
                ]
            }
            try {
                this.downloadingKeys.push(item)
                const res = await downloadSalesZipAPI(payload)
                this.$downloadZipHelper(res, 'zipUrl')
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                const fileInfo = `${row.name} - ${row.period} - ${row.type}`
                const fileError = error.response?.data?.displayMsg || '未知錯誤'

                this.downloadErrorLog.push(`${fileInfo}: ${fileError}</br>`)
                const errorTitle = '下載佣金檔案時發生錯誤'
                const msg =
                    this.downloadErrorLog.reduce((log, memo) => {
                        memo = `${memo}${log}`
                        return memo
                    }) + '若持續出現此問題，請聯繫資訊客服'
                this.$showErrorMessage(errorTitle, msg, () => {
                    this.downloadErrorLog = []
                })
            } finally {
                _.pull(this.downloadingKeys, item)
            }
        }
    },
    computed: {
        tableShowData: function () {
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        excelFileName: function () {
            const period = this.filterLog.period?.label ?? ''
            const month = this.filterLog.month?.label ?? ''
            const name = `${period}_${month}`
            return `佣金查詢_${name}`
        },
        excelConfig: function () {
            return {
                fileName: this.excelFileName,
                filters: this.filterLog
            }
        },
        commissionTableColumns: function () {
            let columns = this.canDownloadZip
                ? _.omit(commissionColumns, 'mTypeName')
                : _.omit(commissionColumns, ['mTypeName', 'downloadZip'])

            // 行政不要加總列
            Object.values(columns).forEach((config) => {
                if (this.$isAdmin()) delete config.total
            })

            return columns
        }
    },
    data() {
        return {
            downloadErrorLog: [],
            downloadingKeys: []
        }
    }
}
</script>

<style lang="scss" scoped>
.download-link-icon {
    min-width: 28px;
    min-height: 28px;
    cursor: pointer;
}

.process-loading-icon {
    min-width: 12px;
    min-height: 12px;
    cursor: wait;
}

:deep(.card) {
    @media screen and (max-width: 576px) {
        .row-container .row.body {
            &.sum-row {
                .cell {
                    &.m-type-name,
                    &.download-zip,
                    &.type,
                    &.branch,
                    &.name {
                        display: none;
                    }
                }
            }
            .cell {
                &.new-period,
                &.type,
                &.branch,
                &.name {
                    width: 50%;
                    &:nth-child(odd) {
                        justify-content: flex-end;
                    }
                    &:nth-child(even) {
                        justify-content: flex-start;
                    }
                }
                &.m-type-name {
                    order: -10;
                    font-weight: 600;

                    &:before {
                        display: none;
                    }
                }

                &.download-zip {
                    order: -9;
                    width: 100%;
                    font-size: 14px;
                    justify-content: flex-end;
                    font-weight: 600;

                    &:before {
                        display: none;
                    }
                    & > div {
                        &:after {
                            content: '下載佣金檔';
                            padding-left: 5px;
                            cursor: pointer;
                        }
                    }
                }

                &.new-period {
                    order: -8;
                    font-weight: 600;

                    &:before {
                        display: none;
                    }
                }

                &.type {
                    order: -7;
                    font-weight: 600;

                    &:before {
                        display: none;
                    }
                }

                &.branch {
                    order: -6;
                    font-weight: 600;

                    &:before {
                        display: none;
                    }
                }

                &.name {
                    order: -5;
                    font-weight: 600;

                    &:before {
                        display: none;
                    }
                }

                &.sale-total {
                    padding-top: 10px;
                    border-top: 1px dashed $fourth-grey;
                }

                &.other-total {
                    padding-bottom: 10px;
                    border-bottom: 1px dashed $fourth-grey;
                }
            }
            &.loading .cell {
                &.m-type-name,
                &.new-period,
                &.branch {
                    justify-content: space-between;
                    width: 100%;
                    &:before {
                        display: block;
                    }
                }
                &.download-zip,
                &.type,
                &.name {
                    display: none;
                }
            }
        }
    }
}
//手機版卡片隱藏表格全螢幕功能
:deep(.tool-option) {
    &.full-size {
        display: flex;
        @media screen and (max-width: 576px) {
            display: none !important;
        }
    }
}
</style>
